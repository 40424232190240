import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import Firebase from 'firebase';
import { Link } from 'react-router-dom';
import { Container } from '@material-ui/core';


class CardDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details:[]
        };
    }

 componentDidMount() {
   
       console.log("detials:"+this.props.data.data)
    }

    

    render() {
        return (
            <Container>
            <div>
        
            <Row className="col-sm-12">
                <Col sm={12} className="products-actions">
                   {/* <Link to={{pathname: `/management-game?id=-hikabskibekcbeod`}}>Terug</Link>
                   
                    <Button className="main-save-button" onClick={()=>this.changeProduct()}>Opslaan</Button>
                  <Link className="align-button-right">Product toevoegen</Link>*/}
                </Col>
                <Col sm={12} className="colum-left">
                    <Row>
                        <Col sm={12} className="add-product-colum">
                            <h2>{this.props.data.data.title}</h2>
                            <p dangerouslySetInnerHTML={{ __html:this.props.data.data.desc}}></p>
                            
                        </Col>
                    </Row>
                </Col>
            
                
   
            </Row>

            </div>


            </Container>
        );
    }
};

export default CardDetail;