import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";
import CardDetail from "../../components/managementGame/cardDetail";




const ManagementGameDetails = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Details" subtitle="F-Fort B.V." md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    
    <CardDetail   data={props.location.state} />
    
  </Container>
);

export default ManagementGameDetails;
