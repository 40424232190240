import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import ButtonFfort from '../elements/button';


class LoginSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userUid:'',
            accesAplications:[]
        }

      }


      componentDidMount(){
        localStorage.removeItem("uniqueId");
        this.setState({
            userUid:Firebase.auth().currentUser.uid,
        })

        console.log(Firebase.auth().currentUser.uid)
this.setState({accesAplications:[]})
            let userAcces = Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid+'/accesto/');
            userAcces.once('value' , snapshot => {
                snapshot.forEach((childSnap) => {
                let state = childSnap.val();

                this.setState(prevState => ({
                
                    accesAplications: [...prevState.accesAplications, state],
                }));

                console.log(state)
             });
        })

        
      }


          
    render() {
        return (
            <Row >
                <Col lg="2"></Col>
                <Col lg="8" className="col-height">
                    <div className="edit-screen-detail list-item-selection">
                        
                        <h4>Kies applicatie</h4>
                        <br/>
                        {this.state.accesAplications.map((application,i)=>(
                            <li><span className="align-left ">{application.name}</span> <span className="align-right"><Link to={{pathname: `/my-desk`,state: {clientId: application.clientId}}}>Bekijk</ Link></span></li>
                            ))}

<li><span className="align-left ">Management-game</span> <span className="align-right"><Link to={{pathname: `/management-game`}}>Bekijk</ Link></span></li>
                        

                       
                       
                    </div>
                </Col>
                <Col lg="2"></Col>
            </Row>         
        );
    }
};

export default LoginSelection;
