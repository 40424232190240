import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'chartjs-plugin-labels';
import PostItExplodedView from './post-it';
import Popup from "reactjs-popup";



class DashboardIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeDrags: 0,
            image:'',
            currentSprint:0,
            sprint:1,
            //personalItems:[{title: 'Test 4', author: "joppe", x: -1215.1513366699219, y: 571.2276916503906, public: false},{title: 'Test 5', author: "joppe", x: -1131.317626953125, y: -20.4246826171875, public:true}],
           personalItems:[],
           colors:[],
           inactiveItems:[],
           threeitems:[33.33,33.33,33.33],
           fouritems:[25,25,25,25],
           fiveitems:[20,20,20,20,20],
           sixitems:[16.6,16.6,16.6,16.6,16.6,16.6],
           sevenitems:[14.28,14.28,14.28,14.28,14.28,14.28,14.28],
            sprintItems:[],
            dragging: false,
            edditedObject:[],
            defSet:[],
            edditing: false,
            deltaPosition: {
              x: 0, y: 0
            },
            position:{
              x: 0, y: 0
            },
            deltaPosition2: {
              x: 0, y: 0
            },
            controlledPosition: {
              x: -400, y: 200
            },
            optionsDoughnut: {
              tooltips:{enabled: false},
              hover:{mode:null},
              legend: { display: false,showTooltips: false, },
              showTooltips: false,
              plugins: {
                
                labels: {
                  render: 'label',
                  position: 'inside',
                  fontColor: 'white'
                }}
                
      
            }
            ,

            options: {
              tooltips:{enabled: false},
              hover:{mode:null},
              legend: { display: false,showTooltips: false, },
              showTooltips: false,
              plugins: {
                
                labels: {
                  render: 'label',
                  position: 'inside',
                  fontColor: 'white'
                }}
            }
            ,
            data :  {


                datasets: [{

                    data: [16.6,16.6,16.6,16.6,16.6,16.6],
                    backgroundColor:["#FFC107","#837804","#d48cf8","#50c8f4","#D81B60","#FE6100"],
                    borderWidth: 2,
                    weight: 5

                }],
                showTooltips: false,
                // These labels appear in the legend and in the tooltips when hovering different arcs
            },
            dataMiddle :  {
              datasets: [{
                  data: [16.6,16.6,16.6,16.6,16.6,16.6],
                  backgroundColor:["#FFC107","#837804","#d48cf8","#50c8f4","#D81B60","#FE6100"],
                  borderWidth: 2,
                  weight: 5

              }],
              showTooltips: false,
              // These labels appear in the legend and in the tooltips when hovering different arcs
          },
          user: '',
          role:false,
          update: false,
          targetTile:'',
          targetDesc:'',
          categoriesPie:[],
          threeCategories:false,
          fourCategories: false,
          fiveCategories: false,
          sixCategories: false,
          sevenCategories: false,
          boardLocked: true,
          
          
        };
        this.fetchedItems = null;
        this.closeInstruction = this.closeInstruction.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
    }

    update= false;

    closeInstruction(){
      console.log('lukt');
      this.setState({
          edditing: false
      })
  }


  handleDrag = (e, ui) => {
    this.setState({
      dragging: true
    })
    let dx = 0 - ui.x;
    let dy = 90 - ui.y;

   let test=  Math.atan2( ui.y, ui.x) * 180 / Math.PI;

    let radianAngle = Math.atan2(-dy, -dx);    // x has the range [-canvas.width/2 ... +canvas.width/2], y is similar
let northUpAngle = radianAngle * 180 / Math.PI + 90; 

    let radians = Math.atan2(ui.y, ui.x)
    var pi = Math.PI;

  
    this.setState({
      position:{
        x: ui.x,
        y:ui.y
      }
    })



  }

  // For controlled component
  adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {x, y} = this.state.controlledPosition;
    this.setState({controlledPosition: {x: x - 10, y}});
  }

  adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {controlledPosition} = this.state;
    const {x, y} = controlledPosition;
    this.setState({controlledPosition: {x, y: y - 10}});
  }

  onControlledDrag = (e, position) => {
    const {x, y} = position;
    this.setState({controlledPosition: {x, y}});
  }

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  }

  checkdouble() {
    let mySet = new Set();
    this.setState({
      defSet: [...new Set([ ...this.state.personalItems,...this.state.inactiveItems,])]
    })
   };



  fetchItems(){


    if(this.fetchedItems)
       this.fetchedItems.off();


      let fetchedItems = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/items/');
      fetchedItems.on('value' , snapshot => {
        this.setState({
          personalItems:[],
          inactiveItems:[],
           sprintItems:[],
          
        })


          snapshot.forEach((childSnap) => {
          let state = childSnap.val();
    

          var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user, match: state.match, x: state.x, y: state.y, public: state.public, notes:state.notes, participants: state.participants, bgcolor: state.bgcolor, category: state.category, selectedkeycat: state.selectedkeycat, sprint: parseFloat(state.sprint), zIndex: state.zIndex, isArchived: state.isArchived, date:state.date };
          this.setState(prevState => ({
            
            personalItems: [...prevState.personalItems, newelement],
            update: false
          }));
        });

          
    
      })
 
      


  }

  getCategories(){
    let fetchedCats = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/categories/');
    fetchedCats.once('value' , snapshot => {
        snapshot.forEach((childSnap) => {
        let state = childSnap.val();
        console.log(state);

        this.setState(prevState => ({
          
          categoriesPie: [...prevState.categoriesPie, state],
          colors:[...prevState.colors, state.bgcolor]
        }));
      });

        
  
    })




  }

  getInformation(){
    let sprint = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/targetSettings/');
    sprint.on('value' , snapshot => {
   
        let state = snapshot.val();
        this.setState({
          targetTitle: state.targetTitle,
          targetDesc: state.targetDescription


        })
    });

    let isActive = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/');
    isActive.on('value' , snapshot => {
   
        let state = snapshot.val();
        this.setState({
          boardLocked: state.live,
 
        })

        if(state.image){
          this.setState({
            image: state.image,
          })
        }
    });

  }


    componentWillMount() {
      console.log('local storage is:'+localStorage.getItem('uniqueId')) ;
      
      this.setState({
        personalItems:[],
        inactiveItems:[],
         sprintItems:[],
         /*currentSprint:this.props.data.sprintNumber,*/
         user:Firebase.auth().currentUser.uid,
      })
this.fetchItems();
this.getInformation();
this.getCategories();




    }

    setRings(){
      if(this.state.categoriesPie.length == 4){
        this.setState({
          fourCategories: true,
          fiveCategories:false,
          sixCategories:false,
          sevenCategories: false,
          data :  {
            datasets: [{
                data: this.state.fouritems,
                backgroundColor:this.state.colors,
                borderWidth: 2,
                weight: 5
            }],
            showTooltips: false,
            // These labels appear in the legend and in the tooltips when hovering different arcs
        },
        dataMiddle :  {
          datasets: [{
              data: this.state.fouritems,
              backgroundColor:this.state.colors,
              borderWidth: 2,
              weight: 5
          }],
        }
        })
      }else if( this.state.categoriesPie.length == 3){
        this.setState({
          threeCategories:true,
          fourCategories: false,
          fiveCategories:false,
          sixCategories:false,
          sevenCategories: false,
          data :  {
            datasets: [{
                data: this.state.threeitems,
                backgroundColor:this.state.colors,
                borderWidth: 2,
                weight: 5
            }],
            showTooltips: false,
            // These labels appear in the legend and in the tooltips when hovering different arcs
        },
        dataMiddle :  {
          datasets: [{
              data: this.state.threeitems,
              backgroundColor:this.state.colors,
              borderWidth: 2,
              weight: 5
          }],
        }
        })
      }
      else if( this.state.categoriesPie.length == 5){
        this.setState({
          fourCategories: false,
          fiveCategories:true,
          sixCategories:false,
          sevenCategories: false,
          data :  {
            datasets: [{
                data: this.state.fiveitems,
                backgroundColor:this.state.colors,
                borderWidth: 2,
                weight: 5
            }],
            showTooltips: false,
            // These labels appear in the legend and in the tooltips when hovering different arcs
        },
        dataMiddle :  {
          datasets: [{
              data: this.state.fiveitems,
              backgroundColor:this.state.colors,
              borderWidth: 2,
              weight: 5
          }],
        }
        })
      }
      else if( this.state.categoriesPie.length == 6){
        this.setState({
          fourCategories: false,
          fiveCategories:false,
          sixCategories:true,
          sevenCategories: false,
          data :  {
            datasets: [{
                data: this.state.sixitems,
                backgroundColor:this.state.colors,
                borderWidth: 2,
                weight: 5
            }],
            showTooltips: false,
            // These labels appear in the legend and in the tooltips when hovering different arcs
        },
        dataMiddle :  {
          datasets: [{
              data: this.state.sixitems,
              backgroundColor:this.state.colors,
              borderWidth: 2,
              weight: 5
          }],
        }
        })
      }
      else if( this.state.categoriesPie.length == 7){
        this.setState({
          fourCategories: false,
          fiveCategories:false,
          sixCategories:false,
          sevenCategories: true,
          data :  {
            datasets: [{
                data: this.state.sevenitems,
                backgroundColor:this.state.colors,
                borderWidth: 2,
                weight: 5
            }],
            showTooltips: false,
            // These labels appear in the legend and in the tooltips when hovering different arcs
        },
        dataMiddle :  {
          datasets: [{
              data: this.state.sevenitems,
              backgroundColor:this.state.colors,
              borderWidth: 2,
              weight: 5
          }],
        }
        })
      }
    }

    componentDidMount(){
      console.log('aantal is:'+this.state.categoriesPie.length);

      this.setRings();
      
    }


    onDroped(object){  
      Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/items/'+object.match).update({
        author: object.author,
        status: 'active',
        key: object.key,
        title: object.title,
        public: "true",
        match: object.match,
        notes: object.notes,
        user: object.user,
        x: this.state.position.x,
        y: this.state.position.y,
        sprint: 1,
        zIndex: object.zIndex,
        isArchived: false
        
    }).then((data)=>{    
      var nd = new Date();
      var d = nd.getDate();
      var m = nd.getMonth() + 1;
      var y = nd.getFullYear();

      var dateStamp = d + '-' + m + '-' + y;
    this.setState({update: true});
    if(this.state.position.x != object.x && this.state.position.y != object.y){
      var newPostKey = Firebase.database().ref().child('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/').push().key;
      Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/'+newPostKey).set({
        user: this.state.user,
        key: newPostKey,
        date:dateStamp,
        title: object.title,
        author: object.author,
        postitKey: object.key,
        event: 'De kaart is verschoven.'
      })
    }


   
    }).catch((error)=>{
        //error callback
        console.log('error ' , error)
    })


    }



    onClicked(object) {
      console.log('cliked');
      this.setState({
        edditedObject: object,
        edditing: true,
      })
    }

    clickie(){
      console.log("clikie");
    }

    handleOnStop(object){
    const {dragging} = this.state;
    console.log("draging is:"+dragging);
      this.setState({dragging: false})
      if (dragging) {
        this.onDroped(object);
        console.log('dragging');
       
      } else {
        //this.onClicked(object);
       console.log('clicked element');
      }
    }

    render() {
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
        const {deltaPosition, controlledPosition,deltaPosition2,} = this.state;
        return (
            <Row className="apm-board">
                 <Col lg="2">
                   <Col lg="12">

                   {this.state.boardLocked ?
                    <div className="reservation-block board-locked">
                        <p  style={{ fontWeight:'bold'}}><span class="material-icons icon-margin" style={{position:'relative'}}>https</span> <span style={{position:'absolute', marginTop:'5%', marginLeft:'2%'}}>Bord afgesloten</span></p>
                        <p style={{marginTop:'6%', fontSize:12, position:'absolute', marginRight:'22%'}}>Dit bord is afgesloten. Het is daarom niet meer mogelijk om post-its te verschuiven of te wijzigen.</p>
                        
                    </div>
                    : this.state.image ? <div className="reservation-block-image" style={{backgroundImage: "url(" +this.state.image+ ")"}} >

                    </div>: null}
                    <div className="reservation-block">
                        <p  style={{ fontWeight:'bold'}}>{this.state.targetTitle}</p>
                        <p style={{marginTop:'-5%', fontSize:12}}>{this.state.targetDesc}</p>
                        
                    </div>
                   </Col>
                   <Col lg="12">
                    <div className="reservation-block">
                     <p> <img img src={require('../../assets/images/cheers.svg')}  style={{width:'15%',fontWeight:'bold'}}/> Om te vieren</p>
                    </div>
                   </Col>
                   <Col lg="12">
                    <div className="reservation-block">
                        <p><img img src={require('../../assets/images/parking-solid.svg')}  style={{width:'15%',fontWeight:'bold'}}/> Om ook nog aan te denken</p>
                    </div>
                   </Col>
                 </Col>
                <Col lg="8" className="screen-rondo">

                <div className="great-doughnut">
                <Doughnut data={this.state.data} className="doughnut"  options={this.state.optionsDoughnut}/>
                </div>
                <div className="middle-doughnut">
                <Doughnut data={this.state.data} className="doughnut"  options={this.state.optionsDoughnut}/>
                </div>
                <div  className="pie">
                <Pie data={this.state.dataMiddle} options={this.state.options} ></Pie>
                
                
                </div>
                {this.state.threeCategories ? 
                  <span>
                    <div className="category-one-three">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[0]}}} className="link-to-post">{this.state.categoriesPie[0].name}</Link></p>
                    </div>
                    <div className="category-two-three">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[1]}}} className="link-to-post">{this.state.categoriesPie[1].name}</Link></p>
                    </div>
                    <div className="category-third-three">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[2]}}} className="link-to-post">{this.state.categoriesPie[2].name}</Link></p>
                    </div>

                  </span>
                  : 
                null}
                {this.state.fourCategories ? 
                  <span>
                    <div className="category-one-four">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[0]}}} className="link-to-post">{this.state.categoriesPie[0].name}</Link></p>
                    </div>
                    <div className="category-two-four">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[1]}}} className="link-to-post">{this.state.categoriesPie[1].name}</Link></p>
                    </div>
                    <div className="category-third-four">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[2]}}} className="link-to-post">{this.state.categoriesPie[2].name}</Link></p>
                    </div>
                    <div className="category-four-four">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[3]}}} className="link-to-post">{this.state.categoriesPie[3].name}</Link></p>
                    </div>
                  </span>
                  : 
                null}

                {this.state.fiveCategories ? 
                  <span>
                    <div className="category-one-five">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[0]}}} className="link-to-post">{this.state.categoriesPie[0].name}</Link></p>
                    </div>
                    <div className="category-two-five">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[1]}}} className="link-to-post">{this.state.categoriesPie[1].name}</Link></p>
                    </div>
                    <div className="category-third-five">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[2]}}} className="link-to-post">{this.state.categoriesPie[2].name}</Link></p>
                    </div>
                    <div className="category-four-five">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[3]}}} className="link-to-post">{this.state.categoriesPie[3].name}</Link></p>
                    </div>
                    <div className="category-five-five">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[4]}}} className="link-to-post">{this.state.categoriesPie[4].name}</Link></p>
                    </div>
                  </span>
                  : 
                null}

                {this.state.sixCategories ? 
                  <span>
                    <div className="category-one">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[0]}}} className="link-to-post">{this.state.categoriesPie[0].name}</Link></p>
                    </div>
                    <div className="category-two">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[1]}}} className="link-to-post">{this.state.categoriesPie[1].name}</Link></p>
                    </div>
                    <div className="category-third">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[2]}}} className="link-to-post">{this.state.categoriesPie[2].name}</Link></p>
                    </div>
                    <div className="category-four">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[3]}}} className="link-to-post">{this.state.categoriesPie[3].name}</Link></p>
                    </div>
                    <div className="category-five">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[4]}}} className="link-to-post">{this.state.categoriesPie[4].name}</Link></p>
                    </div>
                    <div className="category-six">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[5]}}} className="link-to-post">{this.state.categoriesPie[5].name}</Link></p>
                    </div>
                  </span>
                  : 
                null}

                {this.state.sevenCategories ? 
                  <span>
                    <div className="category-one-seven">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[0]}}} className="link-to-post">{this.state.categoriesPie[0].name}</Link></p>
                    </div>
                    <div className="category-two-seven">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[1]}}} className="link-to-post">{this.state.categoriesPie[1].name}</Link></p>
                    </div>
                    <div className="category-third-seven">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[2]}}} className="link-to-post">{this.state.categoriesPie[2].name}</Link></p>
                    </div>
                    <div className="category-four-seven">
                        <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[3]}}} className="link-to-post">{this.state.categoriesPie[3].name}</Link></p>
                    </div>
                    <div className="category-five-seven">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[4]}}} className="link-to-post">{this.state.categoriesPie[4].name}</Link></p>
                    </div>
                    <div className="category-six-seven">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[5]}}} className="link-to-post">{this.state.categoriesPie[5].name}</Link></p>
                    </div>
                    <div className="category-seven-seven">
                      <p><Link to={{pathname: `/dashboard/edit-po`, state:{data: this.state.categoriesPie[6]}}} className="link-to-post">{this.state.categoriesPie[6].name}</Link></p>
                    </div>
                  </span>
                  : 
                null}
                
                
                
                
                

                <div>

            <div className="first-text">
            <img img src={require('../../assets/images/1-apm.svg')}  style={{ width: '15%',position: 'absolute',
    left: '41%',
    top: '52%'}}/>

            </div>
            <div className="sevond-text">
            <img img src={require('../../assets/images/3-apm.svg')}  style={{ width: '30%',position: 'absolute',
    left: '34%',
    top: '71%'}}/>
    

            </div>

            <div className="third-text">
            <img img src={require('../../assets/images/3.svg')}  style={{width: '45%',
    left: '26.5%',
    position: 'absolute',
    top: '88%'}}/>
    

            </div>
                </div>

                
                </Col>

                <Col lg="2">
                  <div class="">
                  <Link to={{pathname: `/archive`}} style={{padding:'4% 34%', marginTop:'22%', fontSize:13, color:'white',borderRadius:50, backgroundColor:'rgb(233, 78, 27)',marginBottom:'2%' }}>Bekijk archief</Link>
                  </div>
                  <div className="my-desk-block">
                      <h4>Mijn bureau</h4>
                     {/* {this.state.personalItems.filter(item => item.user == this.state.user || item.public == true).map((l, i) => (
                      <Draggable onDrag={this.handleDrag} defaultPosition={{x:l.x, y:l.y}} onStop={()=> this.handleOnStop(l)}>
                        <div className="box">
                          <div>{l.title}</div>
                          <div>x: {deltaPosition.x.toFixed(0)}, y: {deltaPosition.y.toFixed(0)}</div>
                        </div>         
                      </Draggable>
                     ))}                   
                 {this.state.inactiveItems.filter(item => item.status == 'inactive').map((l, i) => (
                  <Draggable onDrag={this.handleDrag} onStop={()=> this.handleOnStop(l)}>
                    <div className="box">
                      <div><a onClick={()=>console.log(l.title + 'is op geklikt')}>{l.title}</a></div>
                      <div onClick={()=>console.log(l.title + 'is op geklikt')}>Bekijk</div>
                    </div>         
                  </Draggable> 
            ))}*/} 
            {this.state.personalItems.filter(item => (item.user == this.state.user && item.isArchived == false || item.public == "true" && item.isArchived == false) /*&& item.sprint === this.state.currentSprint*/).map((l, i) => (
                  <Draggable onDrag={this.handleDrag} defaultPosition={{x:l.x, y:l.y}} onStop={()=> this.handleOnStop(l)} disabled={this.state.boardLocked}>
                    <div className="box" style={{position: 'absolute', zIndex: l.zIndex, backgroundColor: l.bgcolor}}>
                    <Link to={{pathname: `/dashboard/edit-post`, state:{data: l, isLocked: this.state.boardLocked}}} className="link-to-post"> {l.title}</Link>
                    </div>         
                  </Draggable> 
                  
            ))}
                  </div>



                </Col>

                {this.state.edditing ? <PostItExplodedView postit={{object:this.state.edditedObject}} closeInstruction={this.closeInstruction} fetchItems={this.fetchItems}/> :null}
                
            </Row>



        );
    }
};

export default DashboardIndex;