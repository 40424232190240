import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import DashboardIndex from "../components/dashboard";
import { Link } from "react-router-dom";


const Dashboard = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Gezamelijk doel" subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
      <Link to={{pathname: `/my-desk`}}>Mijn bureau</Link>
     {/**  <img img src={require('../assets/images/Logo-Helmond.svg')}  style={{ width: '20%',
    float:' right',
    left: '75%',
    position: 'relative',
    marginTop: '-4%',
    marginBottom:'1%'}}/>*/}
    </Row>
    <DashboardIndex data={props.location.state}/>
    
  </Container>
);

export default Dashboard;
