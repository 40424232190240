import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import ButtonFfort from '../elements/button';

class EdittedPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploaded: false,
            fullObject:[],
            title:'',
            author:'',
            public:'',
            notes:'',
            errorMessage:'',
            edditing: true,
            category:'',
            selectedkeycat:0,
            bgcolor:'',
            categories:[],
            zIndex:0

        }

      }

      closeInstrucion(){
        this.props.closeInstruction();
        this.props.fetchItems();
    }

    componentDidMount(){

        this.setState({
            fullObject: this.props.postit.object,
            title:this.props.postit.object.title,
            author:this.props.postit.object.author,
            public:this.props.postit.object.public,
            notes:this.props.postit.object.notes,
            category:this.props.postit.object.category,
            selectedkeycat: this.props.postit.object.selectedkeycat,
            zIndex: this.props.postit.object.zIndex
           
        })

        let categories = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/categories/');
        categories.on('value' , snapshot => {
       
            let state = snapshot.val();
            this.setState({
            categories: state
    
    
            })
        });

        console.log(this.state.title);
    }

    editPostIt(){
        this.setState({
            uploaded:true
        })
        Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/items/'+this.state.fullObject.key).update({
            author: this.state.author,
            key: this.state.fullObject.key,
            title: this.state.title,
            public: this.state.public,
            category: this.state.category,
            selectedkeycat: this.state.selectedkeycat,
            bgcolor: this.state.bgcolor,
            match: this.state.fullObject.match,
            notes: this.state.notes,
            user: this.state.fullObject.user,
            zIndex: this.state.fullObject.zIndex

            
        }).then((data)=>{
            //success callback
            console.log('data ' , data)
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })
        
/*
        Firebase.database().ref('/users/'+this.state.fullObject.user+'/items/'+this.state.fullObject.match).update({
            author: this.state.author,
            key: this.state.fullObject.key,
            postit: this.state.title,
            public: this.state.public,
            match: this.state.fullObject.match,
            notes: this.state.notes,
            user: this.state.fullObject.user,
            x: 0,
            y: 0,
            
        }).then((data)=>{
            //success callback
            console.log('data ' , data)
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })*/
    }

    changeCategory(event){
        this.setState({
            uploaded:false
        })
        console.log(event.target.value)
        for(let i =0; i < this.state.categories.length; i++){
            if(this.state.categories[i].key == event.target.value){
                this.setState({
                    category: this.state.categories[i].name, 
                    bgcolor: this.state.categories[i].bgcolor, 
                    selectedkeycat: event.target.value
                });
            }
        }
    }



    render() {
        return (
            <Row className="overlay">
                <Col lg="2"></Col>
                <Col lg="8" className="col-height">
                    <div className="post-it-view">
                        
                        <h4>Post-it bewerken</h4>
                        <h6>Titel</h6>
                        <input type="text" placeholder={this.state.title} value={this.state.title} onChange={event => this.setState({ title: event.target.value, errorMessage:'',uploaded: false})}/>
                        {/*<h6>Auteur</h6>
                        <input type="text" placeholder={this.state.author} value={this.state.author} onChange={event => this.setState({ author: event.target.value, errorMessage:''})}/>*/}
                        <h6 className="post-it-textarew">Notitie</h6>
                        <textarea placeholder="Extra vraag..." value={this.state.notes} onChange={event => this.setState({ notes: event.target.value, errorMessage:'',uploaded: false})}/>
                        <select id="cars" name="cars" className="select-box-bord" onChange={(event) => this.changeCategory(event)} placeholder="Selecteer een gebruiker">
                             <option disabled selected>{this.state.category} </option>
                           {/* <option value="" disabled selected>Selecteer set}</option>*/}
                            {
                            this.state.categories.map((set, i) => (
                                <option value={set.key} key={i}>{set.name}</option>
                                ))
                            }
                        </select>
                        <Button className="ffort-button" onClick={()=>this.editPostIt()}>Bijwerken</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Post-it is bijgewerkt.</span> :null}<br />
                        <span className="post-it-close"><a onClick={()=> this.closeInstrucion()}>Sluiten</a></span>
                    </div>
                </Col>
                <Col lg="2"></Col>
            </Row>         
        );
    }
};

export default EdittedPost;
