import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import ButtonFfort from '../elements/button';

class myDesk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            postits:[],
            user: '',
            active: 0,
            inactive:0,
            role:false,
            currentSprint:0,
            nextDate:'',
            boardLocked: false
        }
      }

   
    handleSignout(){
        Firebase.auth().signOut();
    }

    componentDidMount(){

        if(localStorage.getItem('uniqueId') == undefined){
            localStorage.setItem('uniqueId', this.props.data.clientId)
        }

        let isActive = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/');
        isActive.on('value' , snapshot => {
       
            let state = snapshot.val();
            this.setState({
              boardLocked: state.live,
            })
        });
        
       console.log('local storage is:'+localStorage.getItem('uniqueId')) ;
       // console.log('url is:'+this.props.data);
        this.setState({
            user:Firebase.auth().currentUser.uid,
            uniqueId: localStorage.getItem('uniqueId'),
        })
        let ref = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/items/');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            if(state.user == this.state.user){
                var newelement = {author: state.author, title: state.title, status: state.status, key: state.key, user: state.user,category: state.category, selectedkeycat: state.selectedkeycat};
                this.setState(prevState => ({
                  
                    postits: [...prevState.postits, newelement],
                }));
            }

            if(state.status == 'active' && this.state.user == state.user){
                this.setState({
                    active: this.state.active+1
                })
            }else if(state.status == 'inactive' && this.state.user == state.user){
                this.setState({
                    inactive: this.state.inactive+1
                })
            }
            

            });


        })



        let settings = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/');
        settings.on('value' , snapshot => {
  
            let state = snapshot.val();
  
            this.setState({
                currentSprint: state.currentSprint,
                nextDate: state.till,
               
            })
        })
/*
        let ref = Firebase.database().ref('/users/'+this.state.user+'/items');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            var newelement = {author: state.author, postit: state.postit, status: state.status, key: state.key, user: state.user};
            this.setState(prevState => ({
              
                postits: [...prevState.postits, newelement],
            }));
            if(state.status == 'active' && this.state.user == state.user){
                this.setState({
                    active: this.state.active+1
                })
            }else if(state.status == 'inactive' && this.state.user == state.user){
                this.setState({
                    inactive: this.state.inactive+1
                })
            }

            });


        })*/
    }
    closeBoard(){
        Firebase.database().ref('/admin/').update({
            currentSprint: this.state.currentSprint+1,


            
        }).then((data)=>{
            //success callback
            console.log('data ' , data)
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
     //   const isDropDisabled = this.state.selected.length > 3;
       // console.log('is die false of true:' + isDropDisabled);
        return (
            <Row className="equal-heights">
                <Col lg="6" className="col-height">
                    <div className="two-block-white">
                        <Row >
                            <Col lg="6">
                            <h4>Post-it's</h4>
                            <p>
                                Maak verschillende post-it’s aan en houdt deze gaande weg het proces bij.
                            </p>
                            <Link className="ffort-button" to={{pathname: `/add-my-desk`,state: {}}} style={{padding:'3% 10%', marginTop:'20%', fontSize:13, color:'white',borderRadius:50, backgroundColor:'#e94e1b' }}>Bekijk mijn bureau</Link>
                            </Col>
                            <Col lg="3" className="text-align-center">
                                <h4>Actief</h4>
                                <p className="count-number">{this.state.active}</p>
                            </Col>
                            <Col lg="3" className="text-align-center">
                                <h4>Inactief</h4>
                                <p className="count-number">{this.state.inactive}</p>
                            </Col>
                        </Row>

                    </div>
                </Col>
                
                <Col lg="6" className="col-height">
                <div className="two-block-blue" >
                    <Row>
                        {this.state.boardLocked ? 
                         <Col lg="8" className="closed">
                            <h4 className="scrum-title closed"> Dit bord is gesloten</h4>
                            <p className="board-closed">
                            Dit bord is afgesloten. Het is daarom niet meer mogelijk om post-its te verschuiven of te wijzigen.
                            </p>
                           
                                             
                       </Col>
                        :

                        <Col lg="8">
                        <h4 className="scrum-title">Dit bord loopt tot</h4>
                        <p className="scrum-date">
                        {this.state.nextDate}
                        </p>
                        <Link to={{pathname: `/dashboard`,state: {sprintNumber: this.state.currentSprint}}} style={{padding:'2% 10%', marginTop:'22%', fontSize:13, color:'#e94e1b',borderRadius:50, backgroundColor:'white',marginBottom:'2%' }}>Bekijk bord</Link>
                     
                        </Col>
    }

                    
                    </Row>
                    </div>
                </Col>
{/*
                <Col lg="3">
                    <div className="four-block-white">
                        <h4>APM-sessie</h4>
                        <p className="sprint-date">01-01-2020</p>
                        {this.state.role ?
                            <Button className="ffort-button" onClick={()=> this.closeBoard()}>Bord afsluiten</Button>
                            
                            :
                            <Link to={{pathname: `/dashboard`,state: {}}} style={{padding:'2% 10%', marginTop:'20%', fontSize:13, color:'white',borderRadius:50, backgroundColor:'#e94e1b' }}>Bekijk bord</Link>
    }
                        
                    </div>
                </Col>
*/}

                <Col lg="12">
                    <div className="one-block-white">
                        <h4>Overzicht</h4>
                        <li className="table-head">Omschrijving <span className="status-head">Status</span></li>
                        { this.state.postits.map((item, i) => (
                            <li>{item.title}  <span className="status">{item.status}</span></li>
                        ))}

                    </div>
                </Col>
            

            </Row>         
        );
    }
};

export default myDesk;
