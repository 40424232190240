import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../../components/common/PageTitle";

import { Link } from "react-router-dom";
import ManagementIndex from "../../components/managementGame";


const ManagementView = ({match, location}) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Gezamelijk doel" subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
      
    </Row>
    <ManagementIndex  id={location.search}/>
    
  </Container>
);

export default ManagementView;
