import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Firebase from 'firebase';
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';




class EditPostCom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploaded: false,
            fullObject:[],
            title:'',
            author:'',
            user:'',
            public:'',
            notes:'',
            errorMessage:'',
            edditing: true,
            isPublic: false,
            usersAvaideble:[],
            deletedPost: false,
            participants:[],
            participantsOld:[],
            noParticipants: false,
            categories:[],
            category:'',
            selectedkeycat:0,
            bgcolor:'',
            fullObjectOld:[],
            titleOld:'',
            authorOld:'',
            userOld:'',
            publicOld:'',
            notesOld:'',
            categoryOld:'',
            selectedkeycatOld:0,
            bgcolorOld:'',
            boardLocked: false,
            date:''
           
        };
        
    }

    componentDidMount(){

        console.log("date is"+this.props.postit.data.date)

        let ref = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/participants/');
        ref.on('value' , snapshot => {
            snapshot.forEach((childSnap) => {
            let state = childSnap.val();
            
      
  
            var newelement = {author: state.name, userUid: state.userUid};
            this.setState(prevState => ({
              
                usersAvaideble: [...prevState.usersAvaideble, newelement],
            }));
           
            });
        
        })

        let categories = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/categories/');
        categories.on('value' , snapshot => {
       
            let state = snapshot.val();
            this.setState({
            categories: state
    
    
            })
        });

        let isActive = Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/');
        isActive.on('value' , snapshot => {
       
            let state = snapshot.val();
            this.setState({
              boardLocked: state.live,
            })
        });
        console.log(this.props.postit.data);



        this.setState({
            fullObject: this.props.postit.data,
            title:this.props.postit.data.title,
            author:this.props.postit.data.author,
            user: this.props.postit.data.user,
            public:this.props.postit.data.public,
            notes:this.props.postit.data.notes,
            category:this.props.postit.data.category,
            selectedkeycat: this.props.postit.data.selectedkeycat,
            bgcolor: this.props.postit.data.bgcolor,
            fullObjectOld: this.props.postit.data,
            titleOld:this.props.postit.data.title,
            authorOld:this.props.postit.data.author,
            userOld: this.props.postit.data.user,
            publicOld:this.props.postit.data.public,
            notesOld:this.props.postit.data.notes,
            categoryOld:this.props.postit.data.category,
            selectedkeycatOld: this.props.postit.data.selectedkeycat,
            bgcolorOld: this.props.postit.data.bgcolor,
            date: this.props.postit.data.date,
            
        })

        console.log("test vandaag:"+ this.props.postit.data.title);

        if(this.props.postit.data.participants != null){
            this.setState({
                participants: this.props.postit.data.participants,
                participantsOld: this.props.postit.data.participants,
                noParticipants: true
            })
        }

        if(this.props.postit.data.user == Firebase.auth().currentUser.uid){
            this.setState({
                deletedPost: true
            })
        }

        if(this.props.postit.data.public == 'true'){
            this.setState({
                isPublic: true
            })
        }else{
            this.setState({
                isPublic: false
            })
        }

        console.log(this.state.title);
    }

    itemIsChanged(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() + 1;
        var y = nd.getFullYear();

        var dateStamp = d + '-' + m + '-' + y;
        if(this.state.author != this.state.authorOld){
            var newPostKey = Firebase.database().ref().child('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/').push().key;
            Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/'+newPostKey).set({
              user: this.state.user,
              key: newPostKey,
              title: this.state.title,
              date:dateStamp,
              author: this.state.author,
              postitKey: this.state.fullObject.key,
              event: 'De auteur van de kaart is gewijzigd. Deze is van '+this.state.authorOld+' naar '+this.state.author+' gewijzigd.'
            })
        }else if(this.state.notes != this.state.notesOld){
            var newPostKey = Firebase.database().ref().child('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/').push().key;
            Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/'+newPostKey).set({
              user: this.state.user,
              key: newPostKey,
              date:dateStamp,
              title: this.state.title,
              author: this.state.author,
              postitKey: this.state.fullObject.key,
              event: 'De notitie van de kaart is gewijzigd. Deze is van '+this.state.notesOld+' naar '+this.state.notes+' gewijzigd.'
            })
        }else if(this.state.title != this.state.titleOld){
            var newPostKey = Firebase.database().ref().child('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/').push().key;
            Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/'+newPostKey).set({
              user: this.state.user,
              key: newPostKey,
              date:dateStamp,
              title: this.state.title,
              author: this.state.author,
              postitKey: this.state.fullObject.key,
              event: 'De titel van de kaart is gewijzigd. Deze is van '+this.state.titleOld+' naar '+this.state.title+' gewijzigd.'
            })
        }else if(this.state.category != this.state.categoryOld){
            var newPostKey = Firebase.database().ref().child('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/').push().key;
            Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/'+newPostKey).set({
              user: this.state.user,
              key: newPostKey,
              date:dateStamp,
              title: this.state.title,
              author: this.state.author,
              postitKey: this.state.fullObject.key,
              event: 'De categorie van de kaart is gewijzigd. Deze is van '+this.state.categoryOld+' naar '+this.state.category+' gewijzigd.'
            })
        }else if(this.state.participants != this.state.participantsOld){
            var newPostKey = Firebase.database().ref().child('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/').push().key;
            Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/'+newPostKey).set({
              user: this.state.user,
              key: newPostKey,
              date:dateStamp,
              title: this.state.title,
              author: this.state.author,
              postitKey: this.state.fullObject.key,
              event: 'De deelnemer(s) van de kaart is/zijn gewijzigd.'
            })
        }

    }

    editPostIt(){
        this.setState({
            uploaded:true
        })
        Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/items/'+this.state.fullObject.match).update({
            author: this.state.author,
            key: this.state.fullObject.key,
            title: this.state.title,
            public: this.state.public,
            participants: this.state.participants,
            user: this.state.user,
            match: this.state.fullObject.match,
            notes: this.state.notes,
            x: this.state.fullObject.x,
            y: this.state.fullObject.y,
            category:this.state.category,
            selectedkeycat: this.state.selectedkeycat,
            bgcolor: this.state.bgcolor,
            date: this.state.date
            
        }).then((data)=>{
            this.itemIsChanged();
            this.closeInstrucion();
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })
       
    }

    archiveItem(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() + 1;
        var y = nd.getFullYear();

        var dateStamp = d + '-' + m + '-' + y;

        this.setState({
            uploaded:true
        })
        Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/items/'+this.state.fullObject.match).update({
            isArchived: true,
            dateArchived: dateStamp
            
        }).then((data)=>{
           
            this.closeInstrucion();
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })
        this.itemIsArchived();
    }

    itemIsArchived(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() + 1;
        var y = nd.getFullYear();

        var dateStamp = d + '-' + m + '-' + y;
        var newPostKey = Firebase.database().ref().child('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/').push().key;
        Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/'+newPostKey).set({
          user: this.state.user,
          key: newPostKey,
          date: dateStamp,
          title: this.state.title,
          author: this.state.author,
          postitKey: this.state.fullObject.key,
          event: 'Deze kaart is gearchiveerd.'
        })
    }


    deletedItem(){
        Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/items/'+this.state.fullObject.match).update({
            author: this.state.author,
            key: this.state.fullObject.key,
            user: this.state.user,
            title: this.state.title,
            public: "false",
            status:'inactive',
            match: this.state.fullObject.match,
            notes: this.state.notes,
            user: this.state.fullObject.user,
            x: 0,
            y: 0,
            sprint: 0,
            
        }).then((data)=>{
            this.itemIsDeleted();
            this.closeInstrucion();
        }).catch((error)=>{
            //error callback
            console.log('error ' , error)
        })

    }

    itemIsDeleted(){
        var nd = new Date();
        var d = nd.getDate();
        var m = nd.getMonth() + 1;
        var y = nd.getFullYear();

        var dateStamp = d + '-' + m + '-' + y;
        var newPostKey = Firebase.database().ref().child('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/').push().key;
        Firebase.database().ref('/admin/apps/-RFHbjepwbfwohe/clients/'+localStorage.getItem('uniqueId')+'/settings/logs/'+newPostKey).set({
          user: this.state.user,
          key: newPostKey,
          date:dateStamp,
          title: this.state.title,
          author: this.state.author,
          postitKey: this.state.fullObject.key,
          event: 'Deze kaart is herstelt naar het bureau van de verantwoordelijke.'
        })
    }

    handleUserChange(e){
        console.log(e.target.value);
    }

    changeResponsible(event){
        this.setState({
            uploaded:false
        })
        for(let i = 0; i < this.state.usersAvaideble.length;i++){
            if(this.state.usersAvaideble[i].userUid == event.target.value){
                this.setState({
                    user: event.target.value,
                    author:this.state.usersAvaideble[i].author,
                })
            }
        }

    }

    changeParticipant(event){
        this.setState({
            uploaded:false
        })
        console.log(event.target.value)
        for(let i =0; i < this.state.usersAvaideble.length; i++){
            if(this.state.usersAvaideble[i].userUid == event.target.value){
                var newelement = {author: this.state.usersAvaideble[i].author, userUid: this.state.usersAvaideble[i].userUid};
                this.setState(prevState => ({
                    noParticipants: true,
                    participants: [...prevState.participants, newelement],
                }));
            }
        }

       

    }

    deleteQuestion(key){

        const participants = this.state.participants;
        participants.splice(key, 1);
        this.setState({ participants });

        if(this.state.participants.length < 1){
            this.setState({
                noParticipants: false
            })
        }
      }

      changeCategory(event){
        this.setState({
            uploaded:false
        })
        console.log(event.target.value)
        for(let i =0; i < this.state.categories.length; i++){
            if(this.state.categories[i].key == event.target.value){
                this.setState({
                    category: this.state.categories[i].name, 
                    bgcolor: this.state.categories[i].bgcolor, 
                    selectedkeycat: event.target.value
                });
            }
        }
    }

    
  


  

    render() {
       
        return (
            <Row>
   
                   <Col lg="12" className="post-view-col">
                   <div className="post-it-view">
                        
                        <h4>Post-it bewerken</h4>
                        <h6>Titel</h6>
                        <input type="text" placeholder={this.state.title} value={this.state.title} onChange={event => this.setState({ title: event.target.value, errorMessage:'',uploaded: false})}/>
                        <h6>Post-it loopt tot</h6>
                        <input type="date" placeholder={this.state.date} value={this.state.date} onChange={event => this.setState({ date: event.target.value, errorMessage:'',uploaded: false})} ></input>
                        <h6>Verantwoordelijke</h6>
                        <select id="cars" name="cars" className="select-box-bord" onChange={(event) => this.changeResponsible(event)} placeholder="Selecteer een gebruiker">
                        <option value="" disabled selected>{this.state.author}</option>
                            {
                            this.state.usersAvaideble.map((set, i) => (
                                <option value={set.userUid} key={i}>{set.author}</option>
                                ))
                            }
                        </select>
                       
                        {/*<h6>Gepubliceerd</h6>{
                            this.state.isPublic ?
                            <select id="cars" name="cars" className="select-box-bord" onChange={(event) => this.setState({ public: event.target.value, errorMessage:'',uploaded: false})}>
                                <option value={true} key={0}>Ja</option>
                                    <option value={false} key={1}>Nee</option>
                               
                            </select>
                            :
                            <select id="cars" name="cars" className="select-box-bord" onChange={(event) => this.setState({ public: event.target.value, errorMessage:'',uploaded: false})}>
                                <option value={false} key={0}>Nee</option>
                                <option value={true} key={1}>Ja</option>
                            </select>
                        }*/}
                        <h6 className="post-it-textarew">Deelnemers</h6>
              
                            {this.state.noParticipants ?
                        
                                this.state.participants.map((item, i) => (
                                    <li key={i}><span className="">{item.author}</span><span className="delete-icon"><a onClick={() => this.deleteQuestion(i)}><i class="far fa-trash-alt"></i></a></span></li>
                                ))
                            :
                            <p className="no-participants">Er zijn geen andere deelnemers</p>
                                }
                            <h6 className="post-it-textarew margin-bottom-min" style={{marginBottom:'-5%', marginTop:'4%'}}>Deelnemer Toevoegen</h6>
                             <select id="cars" name="cars" className="select-box-bord" onChange={(event) => this.changeParticipant(event)} placeholder="Selecteer een gebruiker">
                             <option value="" disabled selected>Selecteer nieuwe deelnemer</option>
                           {/* <option value="" disabled selected>Selecteer set}</option>*/}
                            {
                            this.state.usersAvaideble.map((set, i) => (
                                <option value={set.userUid} key={i}>{set.author}</option>
                                ))
                            }
                        </select>

                        
                        <h6 className="post-it-textarew">Notitie</h6>
                        <textarea placeholder="Extra vraag..." value={this.state.notes} onChange={event => this.setState({ notes: event.target.value, errorMessage:'',uploaded: false})}/>
                        <h6 className="post-it-textarew margin-bottom-min" style={{marginTop:'5%', marginBottom:'-5%'}}>Categorie</h6>
                        <select id="cars" name="cars" className="select-box-bord" onChange={(event) => this.changeCategory(event)} placeholder="Selecteer een gebruiker">
                             <option disabled selected>{this.state.category} </option>
                           {/* <option value="" disabled selected>Selecteer set}</option>*/}
                            {
                            this.state.categories.map((set, i) => (
                                <option value={set.key} key={i}>{set.name}</option>
                                ))
                            }
                        </select>
                        {this.state.boardLocked ? null :
                        <span><Button className="ffort-button" onClick={()=>this.archiveItem()}>Archiveren</Button> <Button className="ffort-button" onClick={()=>this.editPostIt()}>Bijwerken</Button> {this.state.uploaded ?<span className="post-it-update"> <i class="fas fa-check"></i>  Post-it is bijgewerkt.</span> :null}<br /></span>}
                        <span className="post-it-close"> <Link to={{pathname: `/dashboard`,state: {}}}>Terug naar bord</Link></span>{this.state.boardLocked ? null : this.state.deletedPost ?  <span className="post-it-delete"><a onClick={()=> this.deletedItem()}>Verplaats naar bureau</a></span> : null}
                    </div>
                   </Col>
   
                
            </Row>



        );
    }
};

export default EditPostCom;