import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import LoginSelection from "../components/loginSelection";



const LoginSelectionScreen = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title={"Kies Bord"} subtitle="F-Fort" md="12" className="ml-sm-auto mr-sm-auto" />
      
    </Row>
    <LoginSelection />
    
  </Container>
);

export default LoginSelectionScreen;
