

export default function() {
  return [
    {
      title: "Mijn bureau",
      to: "/my-desk",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    } ,
    {
      title: "APM Tool",
      htmlBefore: '<i class="material-icons">leaderboard</i>',
      to: "/dashboard",
    }
    ,
    {
      title: "Archief",
      htmlBefore: '<i class="material-icons">all_inbox</i>',
      to: "/archive",
    }
    ,
    {
      title: "Wissel van bord",
      htmlBefore: '<i class="material-icons">compare_arrows</i>',
      to: "/choose-board",
    }
    ,

  /* Pas in fase 2,
    {
      title: "Rapporten",
      htmlBefore: '<i class="material-icons">insert_chart_outlined</i>',
      to: "/tables",
    },
  */
    
  ];
}
