import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, ModalLayout } from "./layouts";

// Route Views

import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

import Archive from "./views/ArchiveScreen";
import CategoryOverview from "./views/ArchiveCategoryScreenOverview";
import SetOverview from "./views/ArchiveSetScreenOverview";
import addArchiveScreen from "./views/ArchiveAddScreen";
import LoginScreen from './views/LoginScreen';
import Dashboard from "./views/Dashboard";
import Calendar from "./views/CalendarScreen";
import myDeskView from "./views/MyDesk";
import AddToMyDesk from "./views/AddToMyDesk";
import Logging from "./views/LoggingScreen";
import EditPostView from "./views/EditPostScreen";
import LoginSelectionScreen from "./views/LoginSelectionScreen";
import PoEditScreen from "./views/PoEditScreen";
import ManagementView from "./views/mangementGame/ManagementGameView";
import ManagementGameDetails from "./views/mangementGame/ManagementGameDetailView";


export default [
  {
    path: "/",
    exact: true,
    layout: ModalLayout,
    component: () => <Redirect to="/choose-board" />
  },
  {
    path: "/dashboard",
    exact: true,
    layout: ModalLayout,
    component: Dashboard
  },
  {
    path: "/dashboard/edit-post",
    exact: true,
    layout: ModalLayout,
    component: EditPostView
  },
  {
    path: "/dashboard/edit-po",
    exact: true,
    layout: ModalLayout,
    component: PoEditScreen
  },

  {
    path: "/archive/",
    exact: true,
    layout: DefaultLayout,
    component: Archive
  },
  {
    path: "/loggs",
    exact: true,
    layout: DefaultLayout,
    component: Logging
  },
  {
    path: "/category-overview/:catname",
    exact: true,
    layout: DefaultLayout,
    component: CategoryOverview
  },
  {
    path: "/set-overview/:catname/:setname/:categorykey/:setkey",
    exact: true,
    layout: DefaultLayout,
    component: SetOverview
  },
  {
    path: "/add-archive",
    exact: true,
    layout: DefaultLayout,
    component: addArchiveScreen
  },
  {
    path: "/user-profile-lite",
    exact: true,
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    exact: true,
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    exact: true,
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    exact: true,
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/agenda",
    layout: DefaultLayout,
    exact: true,
    component: Calendar
  },
  {
    path: "/tables",
    exact: true,
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    exact: true,
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/my-desk",
    exact: true,
    layout: DefaultLayout,
    component: myDeskView
  },{
    path: "/add-my-desk",
    layout: DefaultLayout,
    exact: true,
    component: AddToMyDesk
  },
  {
    path: "/choose-board",
    layout: ModalLayout,
    exact: true,
    component: LoginSelectionScreen
  },
  {
    path: "/login",
    exact: true,
    layout: ModalLayout,
    component: LoginScreen
  },
];
