import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import MyDesk from "../components/my-desk";


const myDeskView = (props) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="Mijn bureau" subtitle="APM-tool " md="12" className="ml-sm-auto mr-sm-auto" />
    {/*   <img src={require('../assets/images/Logo-Helmond.svg')}  style={{ width: '21%',
    float:' right',
    left: '77%',
    position: 'relative',
    marginTop: '-5%',
    marginBottom:'1%'}}/>*/}
    </Row>
    <MyDesk data={props.location.state}/>
    
  </Container>
);

export default myDeskView;
