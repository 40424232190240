import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Row, Col, Card, CardHeader, CardBody, Button } from "shards-react";
import { Link } from 'react-router-dom';
import Firebase from 'firebase';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'chartjs-plugin-labels';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Popup from "reactjs-popup";

const items =[{isArchived: false,public:true, x:0, y:0,zIndex:1,backgroundColor:'yellow',title:'test 1'}]

class ManagementIndex extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          sets:[],
            selectedCat:'',
            items:[],
            openItem:false,
            tileOpen:[],
            table:[],
            openSaveBoard:false,
            name:'',
            isTile:true,
            amountSets:0,
            currentCounter:0,
            companyImage:false,
            companyImageUrl:'',
            companyName:false,
            companyNameString:'',
            id:'',
            biggerOne:false,
            descNew:'',
            newItem:'',
            chosenNewCat:'selected',
            addnewItem: false,
            chosenCats:[],
            filterChosen:'',
            itemsChosen:[],
            showItem:false,
            title:'',
            loader:false,
            desc: '',
            key:''
          
          
        };

    }
    currentCounter =0;
    update= false;

    closeInstruction(){
      console.log('lukt');
      this.setState({
          edditing: false
      })
  }


  handleDrag = (e, ui) => {
    this.setState({
      dragging: true
    })
    let dx = 0 - ui.x;
    let dy = 90 - ui.y;

   let test=  Math.atan2( ui.y, ui.x) * 180 / Math.PI;

    let radianAngle = Math.atan2(-dy, -dx);    // x has the range [-canvas.width/2 ... +canvas.width/2], y is similar
let northUpAngle = radianAngle * 180 / Math.PI + 90; 

    let radians = Math.atan2(ui.y, ui.x)
    var pi = Math.PI;

  
    this.setState({
      position:{
        x: ui.x,
        y:ui.y
      }
    })



  }

  // For controlled component
  adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {x, y} = this.state.controlledPosition;
    this.setState({controlledPosition: {x: x - 10, y}});
  }

  adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {controlledPosition} = this.state;
    const {x, y} = controlledPosition;
    this.setState({controlledPosition: {x, y: y - 10}});
  }

  onControlledDrag = (e, position) => {
    const {x, y} = position;
    this.setState({controlledPosition: {x, y}});
  }

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  }

  checkdouble() {
    let mySet = new Set();
    this.setState({
      defSet: [...new Set([ ...this.state.personalItems,...this.state.inactiveItems,])]
    })
   };

   changeActive(product){

    for(let k = 0; k <this.state.products.length; k++){
            this.state.products[k].checked = false;
            this.state.products[k].active = 'not-active';
            
            this.setState({
                products: this.state.products,
                selectedProduct: [],
                isProductSelected:false
            })
           
         
    }

    for(let i = 0; i < this.state.products.length; i++){
        if(this.state.products[i].id === product.id && this.state.products[i].checked === false){
            this.state.products[i].active = 'active';
            this.state.products[i].checked = true;

            this.setState({
                products: this.state.products,
                selectedProduct: this.state.products[i],
                isProductSelected:true
            })
        }else if(this.state.products[i].id === product.id && this.state.products[i].checked === true){
            this.state.products[i].active = 'not-active';
            this.state.products[i].checked = false;
            this.setState({
                products: this.state.products,
            })
        }
    }}

    changeCss(key,title){

      let inputStyle =''
      if(key === '-iygekfewhfivw'){
        return ' hexagon mensen ' 
      }else if(key === '-MZOZ8WDit42AJWAKw2I'){
        return 'hexagon technologie'
      }else if(key === '-MZWpEx2986T3lYaFN3O'){
        return 'hexagon proces'
      }else if(key === '-MZX-vI0V_fpOMoLLOaS'){
        return 'hexagon people-baseline'
      }
      else if(key === '-MZX24M1z5IjRWnHk9Ex'){
        return'hexagon practice-baseline'
      } else if(key === '-MZX4HR3WEUq-rw4mzPw'){
        return 'hexagon perspective-baseline'
      }else if(key === '-MZX8kUtt_4AQGJk0BDd'){
        return 'hexagon why-mvi'
      }else if(key === '-MZXBERhNdmDxMCVMk3_'){
        return 'hexagon principes-mvi'
      }else if(key === '-MZXDBysuFANzTPfo1ft'){
        return 'hexagon drijfveren-mvi'
      }else if(key === '-MZXHO9dQQ3A67ny6h81'){
        return 'hexagon overwegingen-mvi'
      }else if(key === '-MZXKdXlPgWpjKBnBFsF'){
        return 'hexagon thema-mvi'
      }else if(key === '-MZXVhXELY_4w_rE1dzu'){
        return 'hexagon belbin'
      }else if(key === '-M_RMP1SosdgDFquLMI5'){
        return 'hexagon prince-2-principes'
      }
      else if(key === '-M_RP8a7RrvDG8r4LFgl'){
        return 'hexagon prince-2-themas'
      }
      else if(key === '-M_RQQiMPB6pHnDaibzd'){
        return 'hexagon prince-2-processen'
      }   else if(key === '-Mai6Udtm7UuXVyFrxLs'){
        return 'hexagon fasemodel'
      } 
      else if(key === '-MaTUZK5cgHMnOfgQUve'){
        return 'hexagon toekomst'
      }
      else if(key === '-Mb1593P5_0Fv87COKSg'){
        return 'hexagon kansen'
      }
      else if(key === '-Mb15FJV3FkjqGEMqZ7M'){
        return 'hexagon belemmeringen'
      } else if(title === '1. Geen Armoede'){
        return 'hexagon sdg-one'
      }else if(title === '2. Geen Honger'){
        return 'hexagon sdg-two'
      }
      else if(title === '3. Goede gezondheid en welzijn'){
        return 'hexagon sdg-three'
      } else if(title === '4. Kwaliteitonderwijs'){
        return 'hexagon sdg-four'
      }
      else if(title === '5. Gendergelijkheid'){
        return 'hexagon sdg-five'
      }
      else if(title === '6. Schoon water en sanitair'){
        return 'hexagon sdg-six'
      }
      else if(title === '7. Betaalbare en duurzame energie'){
        return 'hexagon sdg-seven'
      }
      else if(title === '8. Waardig werk en economische groei'){
        return 'hexagon sdg-eight'
      }
      else if(title === '9. Industrie, innovatie en infrastructuur'){
        return 'hexagon sdg-nine'
      }
      else if(title === '10. Ongelijkheid verminderen'){
        return 'hexagon sdg-ten'
      }
      else if(title === '11. Duurzame steden en gemeenschappen'){
        return 'hexagon sdg-eleven'
      }
      else if(title === '12. Verantwoorde consumptie en productie'){
        return 'hexagon sdg-twelve'
      } else if(title === '13. Klimaatactie'){
        return 'hexagon sdg-thirtheen'
      }else if(title === '14. Leven in het water'){
        return 'hexagon sdg-fourteen'
      }
      else if(title === '15. Leven op het land'){
        return 'hexagon sdg-fiveteen'
      }
      else if(title === '16. Vrede, justitie en sterke publieke diensten'){
        return 'hexagon sdg-sixteen'
      }
      else if(title === '17. Partnerschap om doelstellingen te bereiken'){
        return 'hexagon sdg-seventeen'
      }
      else if(key === '-McfTxPzwjrNv5Zdz3lV'){
        return 'hexagon score'
      }
      else if(key === '-McfU5uPqLNUMGPR0Q9W'){
        return 'hexagon belemmeringen-hkt'
      }
      else if(key === '-McfU28NO4NOXavMbJia'){
        return 'hexagon kansen-hkt'
      }
      
      
      else{
        return 'hexagon default'
      }
  
      return inputStyle
    }

   getCards(id){

                let items = Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/categories/'+id+'/items');
                items.on('value' , snapshot => {
                    snapshot.forEach((childSnap) =>{
                        let item = childSnap.val();
           
                        let newItem = {key: item.key,title: item.title,desc: item.desc, checked: false,active:'not-active', categoryId: id,isArchived: false,public:true, x:0, y:0,isActive:false,image:item.image }
                        this.setState(prevState =>({
                            items: [...prevState.items, newItem]
                        }))
                 
                        
                    })
                    
                })

  }


  
  splittedItem = [];


  getCardsAuto(object){
console.log(object)
const query = new URLSearchParams(this.props.id)

this.setState({items:[]})
    let items = Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/categories/'+object.catKey+'/items');
                items.on('value' , snapshot => {
                    snapshot.forEach((childSnap) =>{
                        let item = childSnap.val();
           
                        let newItem = {key: item.key,title: item.title,desc: item.desc, checked: false,active:'not-active', categoryId: object.catKey,isArchived: false,public:true, x:0, y:0,isActive:false,image: item.image }
                        console.log(newItem.title)
                        this.setState(prevState =>({
                            items: [...prevState.items, newItem]
                        }))

                        Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/saves/'+item.key).update({
                          key: item.key,title: item.title,desc: item.desc, checked: false,active:'not-active', categoryId: object.catKey,isArchived: false,public:true, x:0, y:0,isActive:false,
                  
                          
                      })
                  
                 
                        
                    })
                    
                })


  }

  addToArray(id){
    if(this.state.chosenCats.includes(id)){
console.log('already exist')
    }else{
      console.log('here'+this.state.sets.length)

      let ref = Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/categories/');
      ref.on('value' , snapshot => {
  

          snapshot.forEach((childSnap) => {
              let state = childSnap.val();

              if(state.key === id){
                if (!(this.state.chosenCats.filter(e => e.key === id).length > 0)){
                  console.log("wel:"+state.name)
                  let newcat ={key:state.key,name:state.name}
                  this.setState(prevState=>({
                    chosenCats: [...prevState.chosenCats, newcat ]
                  }))
                 
                }
                else{
                  console.log("niet:"+state.name)
                }
               
              
           
          }


          })
      });

      
    }
    
  }

    componentWillMount() {
this.setState({chosenCats:[]})

this.setState({itemsChosen:[]})
      const query = new URLSearchParams(this.props.id)
        this.setState({
            name: query.get('id')

        })

        localStorage.setItem('managementKey',query.get('id'))

console.log(query.get('id'))
let imageCompany = Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id'));
imageCompany.on('value' , snapshot => {
  let state = snapshot.val();
  if(state.image != undefined){
    this.setState({
      companyImage:true,
                companyImageUrl:state.image,
    })
  }
 


 
})


this.setState({itemsChosen:[]})

        let ref = Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/saves');
      ref.on('value' , snapshot => {
        this.setState({items:[],chosenCats:[],itemsChosen:[]})
        snapshot.forEach((childSnap) => {
          let item = childSnap.val();
           
          let newItem = {key: item.key,title: item.title,desc: item.desc, checked: false,active:'not-active', categoryId: item.categoryId,isArchived: item.isArchived,public:true, x:item.x, y:item.y,isActive:false,image: item.image }
          console.log(newItem.title)
          if(item.isArchived === false){
            this.setState(prevState =>({
              items: [...prevState.items, newItem]
            }))
        
          }else if(item.isArchived === true){
            this.setState(prevState =>({
              itemsChosen: [...prevState.itemsChosen, newItem]
            }))
  
          }
          this.addToArray(item.categoryId)

  
       

          let ref = Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/categories/');
          ref.on('value' , snapshot => {
             
     this.setState({sets:[]})
              snapshot.forEach((childSnap) => {
                  let state = childSnap.val();
  
                  let newSet ={color: state.color, key:state.key, desc:state.desc,name:state.name,checked: false,active:'not-active' }
  
                  this.setState(prevState => ({
                      sets: [...prevState.sets, newSet]
                  }))
  
  
              })
          });

         
          //this.getCardsAuto(state)
         // this.addToArray(item.categoryId)
      })

      

       

         
   
      });

   
      /*let ref = Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/categories/');
      ref.on('value' , snapshot => {
         
 this.setState({sets:[]})
          snapshot.forEach((childSnap) => {
              let state = childSnap.val();

              let newSet ={color: state.color, key:state.key, desc:state.desc,name:state.name,checked: false,active:'not-active' }

              this.setState(prevState => ({
                  sets: [...prevState.sets, newSet]
              }))


          })
      });*/


    }

    rerenderFunct(){
      const query = new URLSearchParams(this.props.id)
      let ref = Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/saves');
      ref.on('value' , snapshot => {
        this.setState({items:[],chosenCats:[],itemsChosen:[]})
        snapshot.forEach((childSnap) => {
          let item = childSnap.val();
           
          let newItem = {key: item.key,title: item.title,desc: item.desc, checked: false,active:'not-active', categoryId: item.categoryId,isArchived: item.isArchived,public:true, x:item.x, y:item.y,isActive:false,image: item.image }
          console.log(newItem.title)
          if(item.isArchived === false){
            this.setState(prevState =>({
              items: [...prevState.items, newItem]
            }))
        
          }else if(item.isArchived === true){
            this.setState(prevState =>({
              itemsChosen: [...prevState.itemsChosen, newItem]
            }))
  
          }
          this.addToArray(item.categoryId)

  
       

          let ref = Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/categories/');
          ref.on('value' , snapshot => {
             
     this.setState({sets:[]})
              snapshot.forEach((childSnap) => {
                  let state = childSnap.val();
  
                  let newSet ={color: state.color, key:state.key, desc:state.desc,name:state.name,checked: false,active:'not-active' }
  
                  this.setState(prevState => ({
                      sets: [...prevState.sets, newSet]
                  }))
  
  
              })
          });

         
          //this.getCardsAuto(state)
         // this.addToArray(item.categoryId)
      })})
    }

    


    onDroped(item){  
      this.setState({
        loader:true
      })

      const query = new URLSearchParams(this.props.id)
    /*  let backupState = this.state.items;

      console.log('hello from here')

      let indexArry = this.state.items.findIndex((obj => obj.key == object.key));

      this.state.items[indexArry].isActive = true;

      this.setState({
        items: this.state.items
      })*/
      Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/saves/'+item.key).update({
        key: item.key,title: item.title,desc: item.desc, checked: false,active:'not-active', categoryId: item.categoryId,isArchived: true,public:true, x: this.state.position.x,  y: this.state.position.y,isActive:false,

        
    }).then(()=>this.setState({
      loader:false
    }))

    }

    onDropedSecond(item){  

      const query = new URLSearchParams(this.props.id)
    /*  let backupState = this.state.items;

      console.log('hello from here')

      let indexArry = this.state.items.findIndex((obj => obj.key == object.key));

      this.state.items[indexArry].isActive = true;

      this.setState({
        items: this.state.items
      })*/
      Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/saves/'+item.key).update({
        key: item.key,title: item.title,desc: item.desc, checked: false,active:'not-active', categoryId: item.categoryId,isArchived: true,public:true, x: this.state.position.x,  y: this.state.position.y,isActive:false,

        
    })

    }

    updateCart(){
      console.log(this.state.key)
      const query = new URLSearchParams(this.props.id)
      Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/saves/'+this.state.key).update({
       isArchived: false

        
    }).then(()=>{
      this.setState({
        showItem:false,
        title:'',
        desc:'',
        key:'',
        itemsChosen:[]
      })

      this.rerenderFunct()

    })

    }

    selectFilter(event){
      console.log(event)

      
      this.setState({
        filterChosen: event
      })
    }



    onClicked(object) {
      console.log('cliked');
      this.setState({
        edditedObject: object,
        edditing: true,
      })
    }

    clickie(){
      console.log("clikie");
    }

    handleOnStop(object){
    const {dragging} = this.state;
    console.log("draging is:"+dragging);
    console.log("draging is:"+object);
      this.setState({dragging: false})
      if (dragging) {
        if(object.x !=  this.state.position.x || object.y !=   this.state.position.y ){
          this.onDroped(object);
        }
        
        console.log('dragging');
       
      } else {
        //this.onClicked(object);
       console.log('clicked element');
      }
    }

    handleOnStopSecond(object){
      const {dragging} = this.state;
      console.log("draging is:"+dragging);
      console.log("draging is:"+object);
        this.setState({dragging: false})
        if (dragging) {
          this.onDropedSecond(object);
          console.log('dragging');
         
        } else {
          //this.onClicked(object);
         console.log('clicked element');
        }
      }


    calcPositionX(i){
      if(i===0){
        let newcount = 0;
        this.currentCounter = this.currentCounter +1;
        return newcount
      }
      else  if(i>0 &&  i<5){
        let newcount = 0;
        this.currentCounter = this.currentCounter +1;
        return newcount
      }else if( i === 5){
        this.currentCounter = 0 ;
        let newcount = 160;
        return newcount
      }else if( i>5 &&  i<10){
        this.currentCounter = this.currentCounter +1;
        let newcount = 160;
        return newcount
      }else if( i === 10){
        this.currentCounter = 0 ;
        let newcount = 320;
        return newcount
      }else if( i>10 && i <15){
        this.currentCounter = this.currentCounter +1;
        let newcount =320;
        return newcount
      }else if( i === 15){
        this.currentCounter = 0 ;
        let newcount = 480;
        return newcount
      }else if( i>15 && i <20){
        this.currentCounter = this.currentCounter +1;
        let newcount = 480;
        return newcount
      }else if( i === 20){
        this.currentCounter = 0 ;
        let newcount = 640;
        return newcount
      }else if( i>20 && i <25){
        this.currentCounter = this.currentCounter +1;
        let newcount = 640;
        return newcount
      }else if( i === 25){
        this.currentCounter = 0 ;
        let newcount = 800;
        return newcount
      }else if( i>25 && i <30){
        this.currentCounter = this.currentCounter +1;
        let newcount = 800;
        return newcount
      }else if( i === 30){
        this.currentCounter = 0 ;
        let newcount = 960;
        return newcount
      }else if( i>30 && i <35){
        this.currentCounter = this.currentCounter +1;
        let newcount = 960;
        return newcount
      }
     

      

    }

    calcPositionY(i){
      if(i===0){
        let newcount = 0;
        this.currentCounter = this.currentCounter +1;
        return newcount
      }
      else  if(i>0 &&  i<5){
        let newcount = 180*i;
        this.currentCounter = this.currentCounter +1;
        return newcount
      }else if( i === 5){
        this.currentCounter = 0 ;
        let newcount = this.currentCounter*180;
        return newcount
      }else if( i>5 &&  i<10){
        this.currentCounter = this.currentCounter +1;
        let newcount =  90*this.currentCounter;
        return newcount
      }else if( i === 10){
        this.currentCounter = 0 ;
        let newcount = this.currentCounter*180;
        return newcount
      }else if( i>10 && i <15){
        this.currentCounter = this.currentCounter +1;
        let newcount =  90*this.currentCounter;
        return newcount
      }else if( i === 15){
        this.currentCounter = 0 ;
        let newcount = this.currentCounter*180;
        return newcount
      }else if( i>15 && i <20){
        this.currentCounter = this.currentCounter +1;
        let newcount =  90*this.currentCounter;
        return newcount
      }else if( i === 20){
        this.currentCounter = 0 ;
        let newcount =this.currentCounter*180;
        return newcount
      }else if( i>20 && i <25){
        this.currentCounter = this.currentCounter +1;
        let newcount =  90*this.currentCounter;
        return newcount
      }else if( i === 25){
        this.currentCounter = 0 ;
        let newcount = this.currentCounter*180;
        return newcount
      }else if( i>25 && i <30){
        this.currentCounter = this.currentCounter +1;
        let newcount =  90*this.currentCounter;
        return newcount
      }else if( i === 30){
        this.currentCounter = 0 ;
        let newcount =this.currentCounter*180;
        return newcount
      }else if( i>30 && i <35){
        this.currentCounter = this.currentCounter +1;
        let newcount =  90*this.currentCounter;
        return newcount
      }
     

      

    }

    setbacktoSet(object){
      let backupState = this.state.items;

      console.log('hello from here')

      let indexArry = this.state.items.findIndex((obj => obj.key == object.key));

      this.state.items[indexArry].isActive = false;
      this.state.items[indexArry].x = 0;
      this.state.items[indexArry].y = 0;

      this.setState({
        items: this.state.items
      })
    }

    saveBoardDef(){
      const query = new URLSearchParams(this.props.id)
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();
      
      let newdate = day + "-" + month + "-" + year;
      var key = Firebase.database().ref().child('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/temp/').push().key;
      Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/temp/'+key).set({
          key: key,
          linkId:query.get('id'),
          items:this.state.items,
          date:newdate
            
      })
    }

    madeBigger(){
      this.setState({
        biggerOne: true,
      })
    }

    madeSmaller(){
      this.setState({
        biggerOne: false,
      })
    }

    addItem(){
      const query = new URLSearchParams(this.props.id)
      var newPostKey = Firebase.database().ref().child('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/saves/').push().key;
      Firebase.database().ref('/admin/apps/-iefkwefivwfbk/clients/-welfefivbnfofbewlf/board/'+query.get('id')+'/saves/'+newPostKey).update({
        key: newPostKey,
        title: this.state.newItem,
        desc: this.state.descNew, 
        checked: false,
        active:'not-active', 
        categoryId: this.state.chosenNewCat,
        isArchived: true,
        public:true, 
        x:0, 
        y:0,
        isActive:false,

        
    }).then(()=>(
      this.setState({
        newItem:'',
        descNew:'',
        chosenNewCat:'selected',
        addnewItem:false
      })
    ))
    }

    onchangeNew(event){
      console.log(event.target.value);
      console.log('heres')
      this.setState({
        chosenNewCat: event.target.value
      })
    }

    showItemGreat(object){
      this.setState({
        key:object.key,
        title:object.title,
        desc: object.desc,
        showItem:true
      })
    }

    render() {
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
        const {deltaPosition, controlledPosition,deltaPosition2,} = this.state;
        return (
          <span>
            <Row className="apm-board">
                <Col sm="12" className="settings-balk">
                <Button onClick={()=>this.saveBoardDef()}>Bord tussen tijds</Button> 
                {this.state.biggerOne ?<Button onClick={()=>this.madeSmaller()}>Bord verkleinen</Button>: <Button onClick={()=>this.madeBigger()}>Bord vergrote</Button>}
                <Button onClick={()=> this.setState({addnewItem:true})}>Voeg item Toe</Button> 
              {/*}
              <Link to={{pathname: `/kaarten`}} >Terug</Link>
                   
                   
                 
                   <Button className="add-button-board" onClick={this.onClick}>Bord vergroten</Button>   <Button className="min-button-board" onClick={this.onClickMin}>Bord verkleinen</Button>*/}
                   <label>Selecteer set</label><br/><br/>
                           <select value={this.state.btwValue} onChange={(event)=>this.selectFilter(event.target.value)}>
                           <option selected="selected" disabled="disabled">Selecteer een set</option>
                           {this.state.chosenCats.map((set)=>(
                               <option value={set.key}>{set.name}</option>
                           ))}
                           </select>
              </Col>
                           
 
                  
                <Col lg="2"></Col>
                <Col lg="8" className="screen-rondo">
                <div id="one-hex" class="hexagon"><div className="centralpoint">{this.state.companyImage ?<img  src={this.state.companyImageUrl}  /> :<img  src={require('../../assets/images/logo-ffort.png')}  />}  </div></div>
                <div  id="two-hex"  class="hexagon"></div>
                <div  id="three-hex"  class="hexagon"></div>
                <div  id="four-hex"  class="hexagon"></div>
                <div  id="five-hex"  class="hexagon"></div>
                <div  id="six-hex"  class="hexagon"></div>
                <div  id="seven-hex"  class="hexagon"></div>
                <div  id="eight-hex"  class="hexagon"></div>
                <div  id="nine-hex"  class="hexagon"></div>
                <div  id="ten-hex"  class="hexagon"></div>
                <div  id="eleven-hex"  class="hexagon"></div>
                <div  id="twelve-hex"  class="hexagon"></div>
                <div  id="thirdteen-hex"  class="hexagon"></div>
                <div  id="fourthteen-hex"  class="hexagon"></div>
                <div  id="fiveteen-hex"  class="hexagon"></div>
                <div  id="sixteen-hex"  class="hexagon"></div>
                <div id="seventeen-hex"  class="hexagon"></div>
                <div id="eigthteen-hex"  class="hexagon"></div>
                <div  id="nineteen-hex"  class="hexagon"></div>

                {/* Bord vergroten is i */}
               {this.state.biggerOne?
               <span>
                <div  id="twentyone-hex"  class="hexagon"></div>
                <div  id="twentytwo-hex"  class="hexagon"></div>
                <div  id="twentythree-hex"  class="hexagon"></div>
                <div  id="twentyfour-hex"  class="hexagon"></div>
                <div  id="twentyfive-hex"  class="hexagon"></div>
                <div  id="twentysix-hex"  class="hexagon"></div>
                <div  id="twentyseven-hex"  class="hexagon"></div>
                <div  id="twentyeight-hex"  class="hexagon"></div>
                <div  id="twentynine-hex"  class="hexagon"></div>
                <div  id="thirty-hex"  class="hexagon"></div>
                <div  id="thirtyone-hex"  class="hexagon"></div>
                <div  id="thirtytwo-hex"  class="hexagon"></div>
                <div  id="thirtythree-hex"  class="hexagon"></div>
                <div  id="thirtyfour-hex"  class="hexagon"></div>
                <div  id="thirtyfive-hex"  class="hexagon"></div>
                <div  id="thirtysix-hex"  class="hexagon"></div>
                <div  id="thirtyseven-hex"  class="hexagon"></div>
                <div  id="thirtyeight-hex"  class="hexagon"></div>
                <div  id="thirtynine-hex"  class="hexagon"></div>
                <div  id="fourteen-hex"  class="hexagon"></div>
                <div  id="fourteenone-hex"  class="hexagon"></div>
                <div  id="fourteentwo-hex"  class="hexagon"></div>
                <div  id="fourteenthree-hex"  class="hexagon"></div>
                <div  id="fourteenfour-hex"  class="hexagon"></div>
                <div  id="fourteenfive-hex"  class="hexagon"></div>
                <div  id="fourteensix-hex"  class="hexagon"></div>
                <div  id="fourteenseven-hex"  class="hexagon"></div>
                <div  id="fourteeneight-hex"  class="hexagon"></div>
                <div  id="fourteennine-hex"  class="hexagon"></div>
                <div  id="fiftyy-hex"  class="hexagon"></div>
                <div  id="fiftyyone-hex"  class="hexagon"></div>
                <div  id="fiftyytwo-hex"  class="hexagon"></div>
                <div  id="fiftyythree-hex"  class="hexagon"></div>
                <div  id="fiftyyfour-hex"  class="hexagon"></div>
                <div  id="fiftyyfive-hex"  class="hexagon"></div>
                <div  id="fiftyysix-hex"  class="hexagon"></div>
                <div  id="fiftyyseven-hex"  class="hexagon"></div>
                <div  id="fiftyyeight-hex"  class="hexagon"></div>
                <div  id="fiftyynine-hex"  class="hexagon"></div>
                <div  id="sixty-hex"  class="hexagon"></div>
                <div  id="sixtyone-hex"  class="hexagon"></div>
                <div  id="sixtytwo-hex"  class="hexagon"></div>
                </span>:null}
            

                
                </Col>

                <Col lg="2" className="item-cols">


            {this.state.loader ? null:this.state.items.filter(item =>  item.categoryId === this.state.filterChosen && item.isArchived === false).map((l, i) => (
                  <Draggable onDrag={this.handleDrag} defaultPosition={{x:this.calcPositionX(i), y:this.calcPositionY(i)}} onStop={()=> this.handleOnStop(l)} >
                    <div className={this.changeCss(l.categoryId, l.title)} style={{position: 'absolute', zIndex: l.zIndex, backgroundColor:'#ed6520!important', textAlign:'center'}}>
                    {l.image === '.'?null:<img  src={l.image}  className="image-sdg"/>}
                    {/*<span className="icons-board"> <Link to={{pathname: `/management-game/details`, state:{data: l}}} className="link-to-post">  <i class="fas fa-eye"></i> </Link>{/*l.isActive ?  <i onClick={()=>this.setbacktoSet(l)}  class="fas fa-trash-alt"></i>:null</span>*/}
               
                    {/*<Link to={{pathname: `/management-game/details`, state:{data: l}}} className="link-to-post">*/}<a className="hover-text"  onClick={()=>this.showItemGreat(l)}>{l.title}</a>{/*</Link>*/}
                    
                    </div>         
                  </Draggable> 
                  
            ))}

{this.state.itemsChosen.filter(item => item.isArchived === true).map((l, i) => (
                  <Draggable onDrag={this.handleDrag} defaultPosition={{x:l.x, y:l.y}} onStop={()=> this.handleOnStop(l)} >
                    <div className={this.changeCss(l.categoryId, l.title)} style={{position: 'absolute', zIndex: l.zIndex, backgroundColor:'#ed6520!important', textAlign:'center'}}>
                    {l.image === '.'?null:<img  src={l.image}  className="image-sdg"/>}
                    {/*<span className="icons-board"> <Link to={{pathname: `/management-game/details`, state:{data: l}}} className="link-to-post">  <i class="fas fa-eye"></i> </Link>{/*l.isActive ?  <i onClick={()=>this.setbacktoSet(l)}  class="fas fa-trash-alt"></i>:null</span>*/}
               
                    {/*<Link to={{pathname: `/management-game/details`, state:{data: l}}} className="link-to-post">*/}<a className="hover-text"  onClick={()=>this.showItemGreat(l)}>{l.title}</a>
                    </div>         
                  </Draggable> 
                  
            ))}
               



                </Col>

                



              
                
            </Row>
{this.state.addnewItem ?
            <Row className="add-item-row">
            <Col sm="3">
                

              </Col>
              <Col sm="6" className="add-item-col">
                <h2>Item aanmaken</h2>
                <label>Titel</label><br/>
                <input type="text" value={this.state.newItem} onChange={(event)=>this.setState({newItem:event.target.value})} /> <br/><br/>
                    
                       <label>Selecteer set</label><br/>
                            <select onChange={(event)=> this.onchangeNew(event)}>
                            <option selected="selected" disabled="disabled">Selecteer een set</option>
                            {this.state.sets.map((set)=>(
                                <option value={set.key}>{set.name}</option>
                            ))}
                            </select><br/><br/>
                <label>Omschrijving</label><br/>
                <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.descNew}
                                config={ {
                                  
                                    toolbar: [ 'italic' ]
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    this.setState({descNew: data,changes:true})
                                } }

                            />
<br/>
<Button onClick={()=>this.addItem()}>Voeg item toe</Button> <Button onClick={()=> this.setState({addnewItem:false})}>Sluiten</Button>
 
              </Col>
              <Col sm="3">
                

                </Col>
                              </Row>:null}

                              {this.state.showItem ?
            <Row className="show-item-row">
            <Col sm="2">
                

              </Col>
              <Col sm="8" className="show-item-col">
                
              <h2>{this.state.title}</h2>
              <p dangerouslySetInnerHTML={{ __html:this.state.desc}}></p>
                            

<Button onClick={()=> this.setState({showItem:false,title:'',desc:''})}>Sluiten</Button> <Button className="btn-danger" onClick={()=> this.updateCart()}>De-activeren</Button>
 
              </Col>
              <Col sm="2">
                

                </Col>
                              </Row>:null}
            </span>


        );
    }
};

export default ManagementIndex;